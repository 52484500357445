import { ensureSingleScript } from '@snapchat/mw-common/client';
import type Hls from 'hls.js';
import { type FC, type PropsWithChildren, useEffect, useRef } from 'react';

import { logError } from '../../helpers/logging';
import { Feature, useFeatureFlags } from '../FeatureFlags';

/**
 * Handles initializing Hls playback for any child video elements that have an .m3u8 source.
 *
 * Usage: Wrap children that contain videos elements who may need to handle hls playback:
 *
 *     <HlsInitializer>
 *       <SomeComponent />
 *     </HlsInitializer>;
 */
export const HlsInitializer: FC<PropsWithChildren> = ({ children }) => {
  const features = useFeatureFlags();
  const hlsInitializerRef = useRef<HTMLDivElement>(null);

  const hlsFeatureEnabled = features[Feature.ENABLE_HLS_PLAYBACK] === 'true';

  useEffect(() => {
    if (!hlsFeatureEnabled) return;

    if (!hlsInitializerRef.current) return;

    const hlsInstances: Hls[] = [];

    const getSourceFromVideo = (video: HTMLVideoElement) => {
      const sources = Array.from(video.querySelectorAll('source'));
      for (const sourceElement of sources) {
        const src = sourceElement.getAttribute('src');
        if (src) return src;
      }

      return null;
    };

    const ensureHlsJs = () => {
      return new Promise<void>(resolve => {
        if (window.Hls) {
          resolve();
          return;
        }

        ensureSingleScript('hls.js', '/hls.min.1.5.18.js', resolve);
      });
    };

    const initializeHlsForVideo = async (video: HTMLVideoElement) => {
      const source = getSourceFromVideo(video);

      if (!source?.endsWith('.m3u8')) return;

      await ensureHlsJs();

      // Hls.js is supported almost everywhere except older versions of Safari on iOS. For those cases, hls should be
      // supported natively, so either way we should be able to play the video.
      if (!window.Hls!.isSupported()) return;

      // We know Hls is available after calling ensureHlsJs
      const hls = new window.Hls!({
        // -1 indicates that Hls.js will start playback at rendition based on connection speed and adjust as needed
        startLevel: -1,
      });
      hls.loadSource(source);
      hls.attachMedia(video);
      hlsInstances.push(hls);
    };

    // Scan children for video elements and initialize Hls for them
    const videos = hlsInitializerRef.current?.querySelectorAll('video') ?? [];
    for (const video of Array.from(videos)) {
      initializeHlsForVideo(video).catch(error => {
        logError({
          component: 'HlsInitializer',
          message: 'Failed to initialize Hls for video',
          error,
        });
      });
    }

    // Set up a MutationObserver to watch for new video elements being added to the dom
    const observer = new MutationObserver(mutations => {
      // First get all added nodes from list of dom mutations
      const childListMutations = mutations.filter(mutation => mutation.type === 'childList');
      const addedNodes = childListMutations.flatMap(mutation => Array.from(mutation.addedNodes));

      // Classify the nodes as video nodes and other nodes
      const videoNodes = addedNodes.filter(node => node.nodeName === 'VIDEO') as HTMLVideoElement[];
      const otherNodes = addedNodes.filter(node => node.nodeName !== 'VIDEO') as HTMLElement[];

      // Combine newly added video nodes with videos found in other nodes for a full list of new videos
      const childVideos = otherNodes.flatMap(node => Array.from(node.querySelectorAll('video')));
      const allNewVideos = [...videoNodes, ...childVideos];

      // Initialize hls for all new videos
      for (const video of allNewVideos) {
        initializeHlsForVideo(video).catch(error => {
          logError({
            component: 'HlsInitializer',
            message: 'Failed to initialize Hls for video',
            error,
          });
        });
      }
    });

    observer.observe(hlsInitializerRef.current, { childList: true, subtree: true });

    // Cleanup:
    // 1) Destroy all Hls instances when the component unmounts
    // 2) Stop listening for dom changes by disconnect the observer
    return () => {
      for (const hls of hlsInstances) {
        hls.destroy();
      }
      observer.disconnect();
    };
  }, [hlsFeatureEnabled]);

  return (
    <article ref={hlsInitializerRef} data-test-id="mwp-hls-init">
      {children}
    </article>
  );
};

import { cx } from '@emotion/css';
import Multiselect from 'multiselect-react-dropdown';
import type { FC, KeyboardEvent } from 'react';
import { memo, useContext, useEffect } from 'react';

import { Black } from '../../constants/colors';
import { Icon } from '../Icon';
import { Field } from './Field';
import type { FormFieldComponentProps, MultiSelectOption } from './Form.types';
import { FormEventType } from './Form.types';
import { FormContext } from './FormContext';
import { getInputPlaceholder } from './formUtils';
import {
  arrowCss,
  inputErrorCss,
  multiSelectDropdownCss,
  multiSelectIconCss,
  placeholderCss,
  selectWrapperCss,
} from './styles';

type FormFieldWithOptions<T> = Partial<T> & {
  options: MultiSelectOption[];
  initialValues?: MultiSelectOption[];
  showCheckbox: boolean;
  name: string;
};
type MultiSelectDropdownField = Omit<FormFieldWithOptions<FormFieldComponentProps>, 'initialValue'>;

/** Singleton array for change detection. */
const initialFieldState: MultiSelectOption[] = [];

/**
 * Select form field that allows for multiple selections.
 *
 * NOTE: we use multiselect-react-dropdown for the dropdown component. Source code:
 * https://github.com/srigar/multiselect-react-dropdown/blob/master/src/multiselect/multiselect.component.tsx
 */
export const MultiSelectDropdown: FC<MultiSelectDropdownField> = memo(props => {
  const {
    name,
    placeholder,
    showCheckbox,
    options,
    required = false,
    initialValues = initialFieldState,
  } = props;
  const { state, dispatch } = useContext(FormContext);
  const value = state.formBody[name];
  const formField = state.fields[name];

  useEffect(() => {
    dispatch({
      type: FormEventType.REGISTER_FIELD,
      name,
      field: {
        initialValue: initialValues,
        required,
      },
    });
  }, [initialValues, dispatch, name, required]);

  const handleSelect = (selectedList: MultiSelectOption[]) => {
    dispatch({
      type: FormEventType.CHANGE_FIELD_VALUE,
      name,
      value: selectedList,
    });
  };

  const onKeyPress = (event: KeyboardEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleRemove = (selectedList: MultiSelectOption[]) => {
    dispatch({
      type: FormEventType.CHANGE_FIELD_VALUE,
      name,
      value: selectedList,
    });
  };

  return (
    <Field {...props} hasError={formField?.hasError} type="MultiSelect">
      <div className={selectWrapperCss}>
        <Multiselect
          options={options}
          selectedValues={value}
          showCheckbox={showCheckbox}
          onSelect={handleSelect}
          onRemove={handleRemove}
          hidePlaceholder={true}
          avoidHighlightFirstOption={true}
          placeholder={getInputPlaceholder(
            placeholder,
            !!props.required && !props.label && !props.richLabel && !formField?.hasError
          )}
          displayValue="name"
          customCloseIcon={<Icon name="cross" fill={Black.V200} className={multiSelectIconCss} />}
          className={cx(multiSelectDropdownCss, placeholderCss, {
            [inputErrorCss]: formField?.hasError,
          })}
          onKeyPressFn={onKeyPress}
        />
        <Icon name="chevron-down" className={arrowCss} />
      </div>
    </Field>
  );
});

MultiSelectDropdown.displayName = 'MultiSelectDropdown';

/**
 * Extracts the space ID, asset ID, asset hash, and file name from an asset URL formatted as follow:
 *
 * https://videos.ctfassets.net/space-id/asset-id/asset-hash/file-name.file-extension
 */
export const getTokensFromAssetUrl = (assetUrl: string) => {
  // remove "base" from url
  const withoutBase = assetUrl.replace('https://videos.ctfassets.net/', '');

  // remove trailing file extension
  const withoutExtension = withoutBase.match(/(.*)\.[^.]+$/)?.[1];

  // extract tokens
  const [spaceId, assetId, assetHash, fileName] = withoutExtension?.split('/') ?? [];

  return {
    spaceId,
    assetId,
    assetHash,
    fileName,
  };
};

import type { Event, EventHint } from '@sentry/types';

type SentryEvent = Event;
type SentryEventHint = EventHint;

const groupByErrors = [
  "Can't find variable: ResizeObserver",
  'Importing a module script failed.',
  'o.flush is not a function',
  'ResizeObserver loop limit exceeded',
  "Cannot read properties of null (reading 'querySelector')",
  // These errors are thrown from the AR download form and don't get grouped well because of payload. They are all the same
  'AR Download Form submit failed',
  'Missing fields in LS download form: eula',
];

/**
 * Sentry hooks before sending data to Sentry.
 *
 * Contains filters and grouping rules for both server and client logging
 */
export function getBeforeSend<T extends SentryEvent, F extends SentryEventHint>(): (
  event: T,
  hint?: F
) => T | PromiseLike<T | null> | null {
  return (event: T, _hint?: F): T | PromiseLike<T | null> | null => {
    const exceptionStackframe = event?.exception?.values?.[0]?.stacktrace?.frames;
    const exceptionMessage = event.exception?.values?.[0]?.value ?? event.message;

    if (!exceptionMessage && !exceptionStackframe) {
      // Return early if there's no exception message to parse.
      return event;
    }

    // Skip 599 messages. They are always a follow-up to another error.
    if (exceptionMessage?.includes('599')) {
      return null;
    }

    // Group this query execution error. They all point to the same content issue.
    const unresolvedQueryErrorMatches = exceptionMessage?.match(
      /Query execution error\. Link from entry(.*)cannot be resolved/
    );

    if (unresolvedQueryErrorMatches?.length && unresolvedQueryErrorMatches[0]) {
      event.fingerprint = [unresolvedQueryErrorMatches[0]];
      return event;
    }

    // These errors don't get grouped well because they don't have stack trace - it's usually because
    // of new deployments that render the old imported module URL invalid.
    if (exceptionMessage?.includes('Failed to fetch dynamically imported module')) {
      event.fingerprint = ['Failed to fetch dynamically imported module'];
      return event;
    }

    // These errors get thrown a couple times across the stackframe and don't get grouped - just check
    // the exception message and that it's thrown from `@improbable-web/grpc-web`
    if (
      exceptionMessage?.includes('Response closed without headers') &&
      exceptionStackframe?.some(frame => frame.module?.includes('@improbable-eng/grpc-web'))
    ) {
      event.fingerprint = ['[grpc-web] Response closed without headers'];
      return event;
    }

    // These errors get thrown from any component that links out. It creates a new issue for each stack trace which
    // is duplicative. Just group them all together. We have a ticket to investigate the error: https://jira.sc-corp.net/browse/WEBP-9306
    for (const groupByError of groupByErrors) {
      if (exceptionMessage?.includes(groupByError)) {
        event.fingerprint = [groupByError];
        return event;
      }
    }

    return event;
  };
}

import { type FC, useContext } from 'react';

import type { FormProps } from './Form';
import { FormContext } from './FormContext';
import { requiredDescriptionCss } from './styles';

type FormMessageProps = Pick<FormProps, 'formRequiredFieldsMessage'>;

export const FormMessage: FC<FormMessageProps> = ({ formRequiredFieldsMessage }) => {
  const { state } = useContext(FormContext);

  const hasRequiredFields = Object.values(state?.fields ?? {}).some(field => field.required);

  if (!hasRequiredFields || !formRequiredFieldsMessage) return null;

  return <p className={requiredDescriptionCss}>{formRequiredFieldsMessage}</p>;
};

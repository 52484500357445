import { css } from '@emotion/css';
import { DeploymentType } from '@snapchat/mw-common';
import { CarouselV3 as CarouselV3SDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext } from 'react';

import { AppContext } from '../../AppContext';
import { Config } from '../../config';
import { UrlParameter } from '../../constants/urlParameters';
import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { Feature, useFeatureFlags } from '../FeatureFlags';
import type { VideoDataProps } from '../Video/types';
import { CarouselV3ImageItem } from './CarouselV3ImageItem';
import { CarouselV3TextItem } from './CarouselV3TextItem';
import { CarouselV3VideoItem } from './CarouselV3VideoItem';
import { carouselV3Query } from './queries';
import {
  type CarouselV3CardItemProps,
  type CarouselV3DataHandlerProps,
  type CarouselV3Props,
  type CarouselV3TextItemProps,
  type CarouselV3Types,
  CarouselV3Layout,
} from './types';

const noShadowCss = css`
  box-shadow: unset;
`;

export const CarouselV3: FC<CarouselV3Props> = props => {
  const featureFlags = useFeatureFlags();
  const enableSnapMotif = featureFlags[Feature.USE_SNAP_MOTIF] === 'true';

  const { isRTL } = useContext(AppContext);
  const { getCurrentUrl } = useContext(AppContext);
  const url = new URL(getCurrentUrl());
  const pauseAnimations =
    Config.deploymentType !== DeploymentType.PRODUCTION &&
    url.searchParams.get(UrlParameter.PAUSE_ANIMATIONS) === 'true';

  const { data } = useContentfulQuery<CarouselV3DataHandlerProps, ContentfulIdVariable>(
    carouselV3Query,
    {
      variables: { id: props.sys.id },
    }
  );

  const carouselItemsType = data?.carouselV3?.contentsCollection?.items?.[0]
    ?.__typename as CarouselV3Types;

  if (!data?.carouselV3) {
    return null;
  }

  const { contentsCollection, mediaAspectRatio } = data.carouselV3;
  const items = contentsCollection.items;

  const singleView =
    carouselItemsType === 'CarouselText' ||
    data?.carouselV3?.layout === CarouselV3Layout.SingleSlideView;

  return (
    <CarouselV3SDS
      isSingleView={singleView}
      autoPlay={!pauseAnimations}
      isRtl={isRTL}
      isOffset={enableSnapMotif}
    >
      {items.map(item => {
        if (item.__typename === 'CarouselCard') {
          const cardItem = item as CarouselV3CardItemProps;
          const hasVideo = cardItem.media.__typename === 'Video';
          const autoPlay = (cardItem.media as VideoDataProps).autoPlay ?? true;
          const enableVideoControls = hasVideo && !autoPlay;

          const shadowCss = cardItem.showShadow ?? true ? undefined : noShadowCss;

          return hasVideo ? (
            <CarouselV3VideoItem
              key={cardItem.sys.id}
              cardItem={cardItem}
              mediaAspectRatio={mediaAspectRatio}
              enableVideoControls={enableVideoControls}
              className={shadowCss}
            />
          ) : (
            <CarouselV3ImageItem
              key={cardItem.sys.id}
              cardItem={cardItem}
              mediaAspectRatio={mediaAspectRatio}
              className={shadowCss}
            />
          );
        }

        if (item.__typename === 'CarouselText') {
          const textItem = item as CarouselV3TextItemProps;
          return <CarouselV3TextItem key={textItem.sys.id} textItem={textItem} />;
        }

        return null;
      })}
    </CarouselV3SDS>
  );
};

CarouselV3.displayName = 'CarouselV3';

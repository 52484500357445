import { cx } from '@emotion/css';
import { parseError } from '@snapchat/core';
import cloneDeep from 'lodash-es/cloneDeep';
import isEmpty from 'lodash-es/isEmpty';
import merge from 'lodash-es/merge';
import type { FC, ReactNode } from 'react';
import { createElement, useCallback, useEffect, useMemo, useReducer } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import type { BaseComponentProps } from '../../types';
import type { TriggerEvent } from '../../types/activationEvents';
import { Button, ButtonType } from '../Button';
import type { FormBody } from './Form.types';
import { FormEventType, FormStateType } from './Form.types';
import { FormContext } from './FormContext';
import { FormErrorMessage } from './FormErrorMessage';
import { FormMessage } from './FormMessage';
import { formReducer, initialFormState } from './formReducer';
import { formCss, placeholderCss, submitButtonCss } from './styles';

/** Proprties for the submit button. */
export interface SubmitProps {
  handleSubmit: () => void;
  /** Whether the submit button isn't clickable. */
  disabled: boolean;
  /** Whether the submission is processing. */
  loading: boolean;
  /** Whether the submit button is in success state. */
  submitSuccess: boolean;
}

/** Properties for the `Form` component. */
export interface FormProps extends BaseComponentProps {
  /** URL at which to submit the form. */
  endpoint: string;
  /** Text of the submit button. */
  submitText?: string;
  /** Text to display if the submission succeeds */
  submitSuccessText?: string;
  /**
   * Data-* properties for the submit button.
   *
   * TODO: This should be part of the `SubmitProps`
   */
  submitTextDataset?: DOMStringMap;
  /**
   * Callback if the submission succeeds.
   *
   * Users may assume that the form clears.
   */
  onSubmitSuccess?: (response: Response, formBody: FormBody) => void;

  /** Callback if the form submission fails. */
  onSubmitFailure?: (error: Error, formBody: FormBody, response?: Response) => void;
  className?: string;

  /** Form children. Not that these are not rendered if `formChildrenRenderer` is passed in. */
  children?: ReactNode;
  formChildrenRenderer?: FC<SubmitProps>; // allow consumers to render form

  /** Extra key=value pairs to send with the form submission. */
  extraParams?: FormBody;
  /** Extra async key-value paris to send with the form submission. */
  extraParamsAsync?: () => Promise<FormBody>;

  /** Called when form body changes. */
  onFormBodyChange?: (formBody: FormBody, lastChangedFieldName?: string, isInit?: boolean) => void;

  /** Error message to render on 400 server response */
  on400ResponseMessage?: string;
  /** Error message to render on 500 server response or unhandled error */
  on500ResponseMessage?: string;
  /** Error message to render on client side invalidation submission */
  onInvalidClientSideSubmissionMessage?: string;
  /** If true, renders error details in an element alongside the error message */
  renderErrorDetails?: boolean;

  /** Message to indicate how to identify required fields */
  formRequiredFieldsMessage?: string;
}

/**
 * The Form component.
 *
 * TODO: Write description.
 */
export const Form: FC<FormProps> = ({
  className,
  children,
  endpoint,
  submitText,
  submitSuccessText,
  submitTextDataset,
  onSubmitSuccess,
  onSubmitFailure,
  onFormBodyChange,
  formChildrenRenderer,
  extraParams,
  extraParamsAsync,
  on400ResponseMessage,
  on500ResponseMessage,
  onInvalidClientSideSubmissionMessage,
  formRequiredFieldsMessage,
  renderErrorDetails = false,
}) => {
  useMotifStyles(MotifComponent.FORM);
  const [state, dispatch] = useReducer(formReducer, cloneDeep(initialFormState));

  // Memoized form context value that only changes when state object changes.
  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  useEffect(() => {
    onFormBodyChange?.(
      cloneDeep(state.formBody),
      state.lastChangedFieldName,
      state.type === FormStateType.INITIAL
    );
  }, [onFormBodyChange, state.formBody, state.lastChangedFieldName, state.type]);

  // On Submit Handling
  const handleSubmit = useCallback((event?: TriggerEvent) => {
    event?.preventDefault(); // Stop forms from submitting.

    dispatch({ type: FormEventType.SUBMIT_TRIGGER });
  }, []);

  // We pipe the submit into 2 phases we can do a validation check in the reducer/
  // before we submit.
  useEffect(() => {
    if (state.type !== FormStateType.SUBMITTING) return;

    async function submitForm() {
      const extraParamsRealized = (await extraParamsAsync?.().catch(() => ({}))) ?? {};
      const formBody = merge({}, state.formBody, extraParams, extraParamsRealized);

      try {
        const response = await fetch(endpoint, {
          method: 'POST',
          body: JSON.stringify(formBody),
          headers: { 'Content-Type': 'application/json' },
        });

        dispatch({ type: FormEventType.SUBMIT_TRIGGER });

        if (!response.ok) {
          const payload = await response.text();
          const error = new Error(
            `Form submit failed. Server Response: ${response.status} ${response.statusText}.\r\n ${payload}`
          );
          // This captures non-2XX responses (400 Bad Request, 401 Unauthorized, 500 Internal Server Error, etc.)
          dispatch({ type: FormEventType.SUBMIT_FAILURE, errorStatusCode: response.status, error });
          onSubmitFailure?.(error, formBody);
        } else {
          // This captures 2XX responses only.
          dispatch({ type: FormEventType.SUBMIT_SUCCESS });
          onSubmitSuccess?.(response, formBody);
        }
      } catch (rawError) {
        const error = parseError(rawError);
        // This captures network and unhandled errors.
        dispatch({ type: FormEventType.SUBMIT_FAILURE, error });
        onSubmitFailure?.(error, formBody);
      }
    }

    void submitForm();
  }, [
    endpoint,
    extraParams,
    extraParamsAsync,
    onSubmitFailure,
    onSubmitSuccess,
    state.formBody,
    state.type,
  ]);

  // TODO: Consider separate useEffects for onSubmitSuccess and onSubmitFailure.

  const disabled =
    state.type === FormStateType.SUBMITTING ||
    state.type === FormStateType.SUBMIT_SUCCESS ||
    isEmpty(state.fields);

  const loading = state.type === FormStateType.SUBMITTING;
  const submitSuccess = state.type === FormStateType.SUBMIT_SUCCESS;
  const submitTextValue =
    state.type === FormStateType.SUBMIT_SUCCESS ? submitSuccessText : submitText;

  let formChildren: ReactNode;

  // TODO: Remove this horrible form child renderer. This has no business
  // being piped through the SDS-M component.
  if (formChildrenRenderer) {
    formChildren = createElement(formChildrenRenderer, {
      handleSubmit,
      disabled,
      loading,
      submitSuccess,
    });
  } else {
    formChildren = (
      <>
        {children}
        <Button
          nativeButtonType="submit"
          onClick={handleSubmit}
          type={ButtonType.Primary}
          size="Large"
          disabled={disabled}
          // There is a split second where state is both submitted and success state at the same time
          loading={loading}
          className={submitButtonCss}
          buttonTextDataset={submitTextDataset}
        >
          {submitTextValue}
        </Button>
      </>
    );
  }

  return (
    <FormContext.Provider value={contextValue}>
      <form className={cx(MotifComponent.FORM, formCss, placeholderCss, className)} noValidate>
        <FormMessage formRequiredFieldsMessage={formRequiredFieldsMessage} />
        {formChildren}
        <FormErrorMessage
          on400ResponseMessage={on400ResponseMessage}
          on500ResponseMessage={on500ResponseMessage}
          onInvalidClientSideSubmissionMessage={onInvalidClientSideSubmissionMessage}
          renderErrorDetails={renderErrorDetails}
        />
      </form>
    </FormContext.Provider>
  );
};

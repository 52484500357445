import { cx } from '@emotion/css';
import { useContentfulImages } from '@snapchat/mw-contentful-client';
import { type GlobalHeaderProps, GlobalHeader } from '@snapchat/mw-global-components';
import type {
  BackgroundColor,
  GlobalHeaderNavItemAlignment,
} from '@snapchat/snap-design-system-marketing';
import { globalHeaderHeight } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Config } from '../../config';
import type { MarketingWebConfiguration } from '../../configTypes';
import { HeaderView, PageLayoutContext } from '../../context/PageLayoutContext';
import { SitewideConfigurationContext } from '../../context/SitewideConfiguration/SitewideConfigurationContext';
import { Anchor } from '../Anchor';
import { CartIcon } from '../Cart';
import { useExperiment } from '../Experiment';
import { Feature, useFeatureFlags } from '../FeatureFlags';
import { DisplayRegion, GlobalHeaderCta } from '../GlobalHeaderCta/GlobalHeaderCta';
import { GlobalHeaderLogo } from '../GlobalHeaderLogo';
import { GlobalHeaderSearch } from '../GlobalHeaderSearch';
import { LocalNavDesktop, LocalNavDesktopOld } from '../LocalNavDesktop';
import { LocalNavMobile, LocalNavMobileOld } from '../LocalNavMobile';
import { useConsumerContext } from '../useConsumerContext';
import {
  globalHeaderSearchContainerDesktopOpenCss,
  globalHeaderSearchContainerMobileCss,
  headerContainerCss,
  noMarginCss,
  searchOpenCss,
  siteNameCss,
} from './Header.styles';
import { convertToPathAwareNavigatorGroups } from './headerNavGroupUtils';
import { convertToPathAwareNavItems } from './headerPathUtils';
import { HeaderPortal } from './HeaderPortal';
import { setHeaderHeight, totalHeaderHeightCssVar } from './headerSizeUtils';

type Props = {
  config: MarketingWebConfiguration;
};

export const Header: FC<Props> = ({ config }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const location = useLocation();
  const { checkCriteria } = useExperiment();
  const { headerView, headerBackgroundColorOverride } = useContext(PageLayoutContext);
  const { sitewideValues } = useContext(SitewideConfigurationContext);
  const { getImageSources } = useContentfulImages();

  const featureFlags = useFeatureFlags();
  const enableSearchFeature = featureFlags[Feature.ENABLE_SITE_SEARCH] === 'true';
  const shouldUseMegaMenu = featureFlags[Feature.USE_MEGA_MENU] === 'true';

  const { isUrlCurrent } = useConsumerContext();

  const localNavProps = sitewideValues?.navigationBar;

  const callsToActionCollection = localNavProps?.callsToActionCollection;

  const headerMenuItemGroups = convertToPathAwareNavigatorGroups(
    localNavProps?.headerMenuItemGroupsCollection?.items,
    isUrlCurrent,
    checkCriteria,
    getImageSources
  );

  const navigatorItemsCollection = localNavProps?.navigatorItemsCollection;
  // TODO: Delete as part of cleanup for https://jira.sc-corp.net/browse/WEBP-11120
  const pathAwareNavItems = convertToPathAwareNavItems(
    navigatorItemsCollection?.items,
    isUrlCurrent,
    checkCriteria
  );

  const headerContainerRef = useRef<HTMLDivElement>(null);

  const resizeObserver = useMemo(() => {
    // attach resize observer on client
    if (typeof window !== 'undefined') {
      let lastSize = globalHeaderHeight;
      return new ResizeObserver(entries => {
        const { height } = entries[0]!.contentRect;

        if (lastSize !== height) {
          lastSize = height;
          document.documentElement.style.setProperty(totalHeaderHeightCssVar, `${height}px`);
        }
      });
    }
    // sets value for the variable being used in renderHtml to put that value on the root element during SSR
    setHeaderHeight(globalHeaderHeight);

    return undefined;
    // we disable here because we only want it to run once on mount (on ssr and on client)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (headerContainerRef.current && resizeObserver) {
      resizeObserver.observe(headerContainerRef.current, { box: 'border-box' });

      return () => {
        resizeObserver.disconnect();
      };
    }

    return;
  }, [resizeObserver]);

  if (headerView === HeaderView.NO_HEADER) {
    return null;
  }

  // In Order: value from Contentful > value from site Config > default value
  const siteName = localNavProps?.title ?? config.globalNavProps?.siteName ?? '';

  // Construct the props for the GlobalHeader component in stages, starting with default values to use for all layouts
  const globalHeaderProps: GlobalHeaderProps = {
    ...config.globalNavProps,
    backgroundColor:
      headerBackgroundColorOverride ??
      (localNavProps?.backgroundColor as BackgroundColor) ??
      undefined,
    logo: <GlobalHeaderLogo />,
    pathname: location.pathname,
    siteName,
  };

  // Construct the props for the GlobalHeader component in stages, add props for rendering CTAs for relevant layouts
  if (
    headerView === HeaderView.MINIMIZED_HEADER ||
    headerView === HeaderView.REDUCED_HEADER ||
    headerView === HeaderView.FULL_HEADER
  ) {
    globalHeaderProps.cta = (
      <>
        <GlobalHeaderCta callsToAction={callsToActionCollection?.items} />
        <HeaderPortal className={noMarginCss} />
        {Config.shopify && <CartIcon />}
      </>
    );
    globalHeaderProps.endChildrenDesktopClassName = cx(
      config.globalNavProps?.endChildrenDesktopClassName,
      globalHeaderSearchContainerDesktopOpenCss
    );
    globalHeaderProps.endChildrenMobileClassName = cx(
      config.globalNavProps?.endChildrenMobileClassName,
      globalHeaderSearchContainerMobileCss
    );
  }

  // Construct the props for the GlobalHeader component in stages, add remaining props except local navigation
  if (headerView === HeaderView.REDUCED_HEADER || headerView === HeaderView.FULL_HEADER) {
    const shouldRenderSearch = location?.pathname !== '/search' && enableSearchFeature;

    const siteNameLink = (
      <Anchor href={localNavProps?.url} className={siteNameCss}>
        {siteName}
      </Anchor>
    );

    globalHeaderProps.className = cx(
      config.globalNavProps?.className,
      searchOpen ? searchOpenCss : undefined
    );
    globalHeaderProps.search = shouldRenderSearch && (
      <GlobalHeaderSearch key="search" setSearchOpen={setSearchOpen} />
    );
    globalHeaderProps.searchOpen = searchOpen;
    globalHeaderProps.siteName = siteNameLink;
  }

  // Construct the props for the GlobalHeader component in stages, add local navigation
  if (headerView === HeaderView.FULL_HEADER) {
    // Use new item groups if using new mega-menu design, otherwise use old items
    const renderLocalNavDesktop: FC = () =>
      shouldUseMegaMenu ? (
        <LocalNavDesktop items={headerMenuItemGroups} />
      ) : (
        <LocalNavDesktopOld items={pathAwareNavItems} />
      );

    // Use new item groups if using new mega-menu design, otherwise use old items
    const renderLocalNavMobile: FC = () =>
      shouldUseMegaMenu ? (
        <LocalNavMobile items={headerMenuItemGroups} />
      ) : (
        <LocalNavMobileOld items={pathAwareNavItems} />
      );
    const hasCtas = callsToActionCollection?.items.length;

    globalHeaderProps.localNavDesktop = !searchOpen ? renderLocalNavDesktop({}) : null;
    globalHeaderProps.localNavMobile = renderLocalNavMobile({});
    globalHeaderProps.localNavMobileFooter = hasCtas ? (
      <GlobalHeaderCta
        callsToAction={callsToActionCollection?.items}
        displayRegion={DisplayRegion.Screen}
      />
    ) : null;
    globalHeaderProps.navItemAlignment = featureFlags[
      Feature.GLOBAL_NAV_ITEMS_POSITION
    ] as GlobalHeaderNavItemAlignment;
  }

  // Render GlobalHeader component (edge case: no wrapping element for these layouts)
  if (headerView === HeaderView.SIMPLE_HEADER || headerView === HeaderView.MINIMIZED_HEADER) {
    return <GlobalHeader {...globalHeaderProps} showGlobalLinks={false} showNavScreen={false} />;
  }

  // Render GlobalHeader component (normal case)
  return (
    <div
      data-testid="mwp-header"
      ref={headerContainerRef}
      className={cx(headerContainerCss, config.headerProps?.className)}
    >
      <GlobalHeader {...globalHeaderProps} />
    </div>
  );
};

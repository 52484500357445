import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { fragments as mediaFragments } from '../Media';

export const fragments = {
  all: gql`
    fragment VideoAll on Video {
      ...ContentfulSysId
      media {
        ...AssetAll
      }
      streamUrl
      mobileMedia {
        ...AssetAll
      }
      thumbnailMedia {
        ...AssetAll
      }
      captions {
        ...AssetAll
      }
      autoPlay
      wrap
      externalId
    }
    ${mediaFragments.all}
    ${contentfulSysIdFragment}
  `,
};

export const query = gql`
  query VideoQuery($preview: Boolean!, $locale: String!, $id: String!) {
    video(preview: $preview, locale: $locale, id: $id) {
      ...VideoAll
    }
  }
  ${fragments.all}
`;

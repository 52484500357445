import { localeMap } from '@snapchat/mw-common';
import type { AnchorHTMLAttributes } from 'react';

import { isLocalUrl } from './getLocalPath';
import {
  alwaysPersistQueryParams,
  checkIfPersistParamsDomain,
  checkIfSameTabDomain,
  persistedQueryParams,
} from './redirectTo';
import { appendLocaleParameterToUrl, mergeUrlParameters } from './uri';

interface GetLinkPropsOptions {
  destination: string | URL | undefined;
  currentUrl: URL;
  locale: string;
  newTab?: boolean;
  isDownload?: boolean;
}

const allLocales = Object.keys(localeMap);

/**
 * Helper function for determining properties on an anchor (`<a ...`) for a given url in certain
 * context.
 *
 * Provides utility behaviors:
 *
 * - Triggers same or different tab navigation based on Site Configuration
 * - Trims and/or retains query params based on Site Configuration
 * - Appends `lang` param to external navigation
 */
export function getLinkProps(options: GetLinkPropsOptions): AnchorHTMLAttributes<unknown> {
  if (!options.destination) return {};

  const targetUrl = new URL(options.destination, options.currentUrl);

  const isSameTabDomain = checkIfSameTabDomain(targetUrl, options.currentUrl);
  const isPersistParamsDomain = checkIfPersistParamsDomain(targetUrl, options.currentUrl);
  const openInNewTab = options.newTab !== undefined ? options.newTab : !isSameTabDomain;
  const isFinalTargetLocal = isLocalUrl(targetUrl);

  // if its a download link, we don't need to do anything special, just return the passed in
  // href and always open in new tab to preserve current browsing context
  if (options.isDownload) {
    return {
      href: targetUrl.href,
      rel: isFinalTargetLocal ? 'opener' : 'noopener',
      target: '_blank',
    };
  }

  const queryParamsToPersist = [
    // only persist these query parameters for local and same tab navigation
    ...(isPersistParamsDomain ? persistedQueryParams : []),
    // always persist these query parameters
    ...alwaysPersistQueryParams,
  ];

  mergeUrlParameters(targetUrl, options.currentUrl, queryParamsToPersist);

  // We only append lang=.. parameter to external sites.
  if (!isFinalTargetLocal) {
    appendLocaleParameterToUrl(targetUrl, options.locale);
  }

  // Remove all locale prefixes for local links.
  if (isFinalTargetLocal) {
    const localePrefix = allLocales.find(locale => targetUrl.pathname.match(`^/${locale}(/|$)`));

    if (localePrefix) {
      targetUrl.pathname = targetUrl.pathname.replace(`/${localePrefix}`, '');
    }
  }

  // Mimic behavior of the stripTrailingSlash middleware:
  // Used to prevent errors if a content editor includes this when setting a hyperlink target URL, etc.
  // Purposely does not address behavior for external links
  if (
    isFinalTargetLocal &&
    targetUrl.pathname.length > 1 &&
    targetUrl.pathname[targetUrl.pathname.length - 1] === '/'
  ) {
    targetUrl.pathname = targetUrl.pathname.slice(0, -1);
  }

  // NOTE: Since 2021, all modern browsers have target=_blank imply rel=noopener
  // I.e. Chrome: https://chromium-review.googlesource.com/c/chromium/src/+/1630010
  // but we only want rel=noopener for external (non-local) sites, so we set the
  // rel=opener|noopener explicitly.
  return {
    href: targetUrl.href,
    rel: isFinalTargetLocal ? 'opener' : 'noopener',
    target: openInNewTab ? '_blank' : '_self',
  };
}

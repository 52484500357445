import { Site } from '@snapchat/mw-common';
import { type Motif, defaultMotif } from '@snapchat/snap-design-system-marketing';

import { type FeatureFlags, Feature } from '../components/FeatureFlags';
import { Config } from '../config';
import { arcadiaMotif } from '../sites/arcadia/arcadiaMotif';
import { avalonMotif } from '../sites/avalon/avalonMotif';
import { cheeriosMotif } from '../sites/cheerios/cheeriosMotif';
import { experienceMotif } from '../sites/experience/experienceMotif';
import { snapMotif } from '../sites/snap/snapMotif';

export function getSiteMotif(featureFlags: FeatureFlags): Motif | undefined {
  switch (Config.site) {
    case Site.ARCADIA:
      return arcadiaMotif;
    case Site.AVALON:
      return avalonMotif;
    case Site.CHEERIOS:
      return cheeriosMotif;
    case Site.EXPERIENCE:
      return experienceMotif;

    case Site.SNAP: {
      const enableSnapMotif = featureFlags[Feature.USE_SNAP_MOTIF] === 'true';

      if (enableSnapMotif) {
        return snapMotif;
      }

      return defaultMotif;
    }
    default:
      return defaultMotif;
  }
}

import type { AnchorComponent } from '@snapchat/snap-design-system-marketing';
import { forwardRef, useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import { getLinkProps } from '../../helpers/getLinkProps';
import { isLocalUrl } from '../../helpers/getLocalPath';

/**
 * A custom anchor renderer that does a few usability things:
 *
 * - Use the react router's {@link Link} for local links
 * - Use the {@link getLinkProps} to override the `href`, `target` and `rel` props. This adds url
 *   parameters to outgoing links, and decides whether links open in a new tab or not.
 */
export const Anchor: AnchorComponent = forwardRef((props, ref) => {
  const { currentLocale, getCurrentUrl } = useContext(AppContext);

  // Do nothing if there's no hyperlink reference.
  if (!props.href) {
    return <a {...props} ref={ref} />;
  }

  // Apply updates like path redirects, parameter copying etc.
  const updatedProps = getLinkProps({
    destination: props.href,
    currentUrl: new URL(getCurrentUrl()),
    locale: currentLocale,
    isDownload: props.download,
  });

  const url = new URL(updatedProps.href!, getCurrentUrl());

  if (isLocalUrl(url)) {
    const { href: _, ...nonHrefProps } = props;
    return (
      <Link
        {...nonHrefProps}
        // Note: we ignore the updatedProps here not related to the url.
        ref={ref}
        to={{
          // Have to use this syntax instead of just `url` because it breaks
          // the link.
          pathname: url.pathname,
          search: url.search,
          hash: url.hash,
        }}
        target="_self"
      />
    );
  }

  // IMPORTANT NOTE: The order here means that if the `getLinkProps` returns
  // a `target`, it overrides whatever the user has passed. This is a decision
  // That's easy to revert and there are use-cases for both ways. However
  // there isn't a use-case to force a target on an individual link that doesn't
  // follow the site policy, hense updated propes always override passed-in props.
  return <a ref={ref} {...props} {...updatedProps} />;
});

import { Black, Plain } from '../../constants/colors';
import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const widthVars = {
  '--carousel-card-border-width': '0',
  '--carousel-card-box-shadow': m('--box-shadow-l'),
  '--carousel-card-hover-box-shadow': m('--box-shadow-l'),
  '--carousel-card-landscape-square-desktop-width': '364px',
  '--carousel-card-landscape-square-mobile-width': '311px',
  '--carousel-card-landscape-square-small-mobile-width': '288px',
  '--carousel-card-portrait-desktop-width': '257px',
  '--carousel-card-portrait-mobile-width': '257px',
  '--carousel-card-desktop-grid-gap': '64px',
  '--carousel-card-mobile-grid-gap': '16px',
};

export const defaultCardColors = {
  '--carousel-card-border-color': Plain.TRANSPARENT,
  '--carousel-card-hover-border-color': Plain.TRANSPARENT,
  '--carousel-card-bg-color': Plain.WHITE,
  '--carousel-card-hover-bg-color': Plain.WHITE,
  '--carousel-card-fg-color': Plain.BLACK,
  '--carousel-inactive-dot-color': 'rgba(133, 141, 148, 0.50)',
};

export const contrastBlackCarouselVars: MotifComponentProps<MotifComponent.CAROUSEL> = {
  '--carousel-active-dot-color': Plain.WHITE,
  ...defaultCardColors,
  '--carousel-card-bg-color': Black.V125,
  '--carousel-card-hover-bg-color': Black.V125,
  '--carousel-card-fg-color': Plain.WHITE,
  ...widthVars,
  '--carousel-card-box-shadow': '0px 0px 0px 0 rgba(0, 0, 0, 0)',
  '--carousel-card-hover-box-shadow': '0px 0px 0px 0 rgba(0, 0, 0, 0)',
};

export const contrastYellowCarouselVars: MotifComponentProps<MotifComponent.CAROUSEL> = {
  '--carousel-active-dot-color': Plain.BLACK,
  ...defaultCardColors,
  ...widthVars,
};

export const contrastWhiteCarouselVars: MotifComponentProps<MotifComponent.CAROUSEL> = {
  '--carousel-active-dot-color': Plain.BLACK,
  ...defaultCardColors,
  ...widthVars,
};

export const contrastGrayCarouselVars: MotifComponentProps<MotifComponent.CAROUSEL> = {
  '--carousel-active-dot-color': Plain.BLACK,
  ...defaultCardColors,
  ...widthVars,
};

import omit from 'lodash/omit.js';

import { Site } from './sites';

/**
 * Definitive list of ga4 properties used by Marketing Web Platform.
 *
 * You can view a property by going to https://analytics.google.com/analytics/web/#/p<propertyId>
 *
 * Our policy is that every new site has a GA4 property, so you have to create one and add it to the
 * list when creating a site.
 */
export const googleAnalytics4Properties: Record<Site, number> = {
  [Site.A523]: 292694174,
  [Site.AR]: 265216064,
  [Site.ARCADIA]: 287858207,
  [Site.AVALON]: 327898756,
  [Site.CAREERS]: 327730352,
  [Site.CHEERIOS]: 327913877,
  [Site.CITIZEN]: 327822562,
  [Site.CREATORS]: 284037488,
  [Site.DIVERSITY]: 327894680,
  [Site.EDUCATORS]: 446196364,
  [Site.ENG_BLOG]: 327879893,
  [Site.EXPERIENCE]: 430935078,
  [Site.FOR_BUSINESS]: 272999138,
  [Site.NEWSROOM]: 327160579,
  [Site.ORIGINALS]: 327838878,
  [Site.PARENTS]: 402936625,
  [Site.SANDBOX]: 358873651,
  [Site.TRUST]: 338421395,
  [Site.SNAP]: 327913881,
};

/** List of MWP GA4 properties that receive production traffic. */
export const productionMwpGa4Properties = omit(googleAnalytics4Properties, [Site.SANDBOX]);

import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { ctaFragment } from '../CallToAction';

export const fragments = {
  /** Note: Requires calling query set the `$accordionItemsLimit` gql variable. */
  all: gql`
    fragment AccordionAll on Accordion {
      ...ContentfulSysId
      title
      multipleOpen
      itemsCollection(limit: 30) {
        items {
          ...ContentfulSysId
          ... on AccordionItem {
            title
            body {
              json
            }
            callsToActionCollection(limit: 2) {
              items {
                ... on CallToAction {
                  ...CallToActionAll
                }
              }
            }
          }
        }
      }
    }
    ${contentfulSysIdFragment}
    ${ctaFragment}
  `,
};

export const queries = {
  all: gql`
    query accordionQuery($preview: Boolean!, $locale: String!, $id: String!) {
      accordion(preview: $preview, locale: $locale, id: $id) {
        ...AccordionAll
      }
    }
    ${fragments.all}
  `,
};

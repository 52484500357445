import type { FieldValue } from './Form.types';

export function getStringValue(value: FieldValue): string {
  if (!value) {
    return ''; // Undefined values make React thing the form isn't controlled.
  }
  if (Array.isArray(value)) return value.join(',');
  return String(value);
}

export function getBooleanValue(value: FieldValue): boolean {
  if (typeof value === 'boolean') return value;
  if (typeof value === 'string') return value === 'true';
  if (Array.isArray(value)) return value.length > 0;
  return false;
}

export function getInputPlaceholder(
  placeholder: string | undefined,
  showRequired: boolean
): string | undefined {
  if (placeholder && showRequired) {
    return `${placeholder}*`;
  }

  return placeholder;
}

import type { MediaDataProps } from '../components/Media';
import type { MediaSds } from '../types/Media';

/** Given a MediaDataProps object, return image related MediaSds properties. */
export const parseImageMedia = (imageMedia: MediaDataProps): MediaSds => {
  return {
    imageSource: imageMedia.url,
    imageAltText: imageMedia.description,
    imageSize: {
      height: imageMedia.height,
      width: imageMedia.width,
    },
  };
};

/** Given a MediaDataProps object, return video related MediaSds properties. */
export const parseVideoMedia = (videoMedia: MediaDataProps): MediaSds => {
  return {
    videoSource: videoMedia.url,
  };
};

/**
 * @deprecated Use parseMediaContainer instead. Media should always be wrapped by a Video or Image
 *   content type. There are still exceptions and tech debt that need to be resolved before that is
 *   the case across the board, but going forward, image/video media should always be wrapped by a
 *   Image or Video content type.
 */
export const parseMedia = (media?: MediaDataProps | null): MediaSds => {
  if (!media || !media.url) {
    return {};
  }

  if (media.contentType?.startsWith('video')) {
    return parseVideoMedia(media);
  }

  if (media.contentType?.startsWith('image')) {
    return parseImageMedia(media);
  }

  return {};
};

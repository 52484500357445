import { css } from '@emotion/css';

import { mobileMediaQuery } from '../../../constants';
import { h5Css, h6Css, m, p3Css } from '../../../motif';
import { mediaQueryForRange } from '../../../utils';

const cardTextHeight = '120px';
const arrowIconSize = '24px';

const lineClamp = (lines: number) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const cardCss = css`
  border: ${m('--carousel-card-border-width')} solid ${m('--carousel-card-border-color')};
  border-radius: ${m('--border-radius-l')};
  box-shadow: ${m('--carousel-card-box-shadow')};
  cursor: pointer;
  overflow: hidden;
  position: relative;
  transition: background-color 0.2s linear, border-color 0.2s linear, box-shadow 0.2s linear;
  user-select: none;

  &:hover svg {
    fill: ${m('--action-hover-color')};
  }

  :hover {
    border-color: ${m('--carousel-card-hover-border-color')};
    box-shadow: ${m('--carousel-card-hover-box-shadow')};
  }

  :hover .sdsm-carousel-card-text {
    background-color: ${m('--carousel-card-hover-bg-color')};
    border-color: ${m('--carousel-card-hover-border-color')};
  }
`;

export const linkCss = css`
  color: ${m('--carousel-card-fg-color')};
  display: block;
  text-decoration: none;
`;

export const mediaContainerCss = css`
  /**
   * To help video controls extend full width
   * https://github.sc-corp.net/Snapchat/marketing-web/pull/2555#discussion_r5071157
  */
  position: relative;
  aspect-ratio: 9 / 16;
  margin: calc(${m('--carousel-card-border-width')} * -1);

  > picture {
    pointer-events: none;
  }

  /** To allow object-fit cover for the video element  */
  > div {
    height: 100%;
  }

  & img {
    aspect-ratio: 16 / 9;
  }
`;

export const image169Css = css`
  aspect-ratio: 16 / 9;

  & img {
    aspect-ratio: 16 / 9;
  }
`;

export const image32Css = css`
  aspect-ratio: 3 / 2;

  & img {
    aspect-ratio: 16 / 9;
  }
`;

export const image11Css = css`
  aspect-ratio: 1 / 1;

  & img {
    aspect-ratio: 16 / 9;
  }
`;

export const imageLoadingCss = css`
  background-color: rgb(232, 232, 235);
  overflow: hidden;
  position: relative;

  &::before {
    animation: card-skeleton 2s infinite;
    background: linear-gradient(0.5turn, rgb(232, 232, 235), #fff, rgb(232, 232, 235));
    content: '';
    height: 100%;
    inset: 0;
    opacity: 0.8;
    pointer-events: none;
    position: absolute;
    transform: translateX(-100%);
  }

  @keyframes card-skeleton {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(100%);
    }
  }
`;

export const cardPortraitWidthCss = css`
  width: ${m('--carousel-card-portrait-desktop-width')};

  ${mobileMediaQuery} {
    width: ${m('--carousel-card-portrait-mobile-width')};
  }
`;

export const cardLandscapeWidthCss = css`
  width: ${m('--carousel-card-landscape-square-desktop-width')};

  ${mobileMediaQuery} {
    width: ${m('--carousel-card-landscape-square-mobile-width')};
  }

  ${mediaQueryForRange({ max: 345 })} {
    width: ${m('--carousel-card-landscape-square-small-mobile-width')};
  }
`;

export const singleViewLandscapeWidthCss = css`
  width: 100%;
  /* TODO: explore making this bigger */
  max-width: 700px;
`;

export const textWrapperCss = css`
  position: relative;
  word-break: break-word;
`;

export const textWrapperOverlapCss = css`
  height: ${cardTextHeight};
`;

export const textCss = css`
  align-content: flex-start;
  color: ${m('--carousel-card-fg-color')};
  background-color: ${m('--carousel-card-bg-color')};
  border: ${m('--carousel-card-border-width')} solid ${m('--carousel-card-border-color')};
  border-radius: ${m('--border-radius-l')};
  display: grid;
  gap: 0 ${m('--spacing-m')};
  grid-template-columns: 1fr ${arrowIconSize};
  margin: calc(${m('--carousel-card-border-width')} * -1);
  min-height: ${`calc(${cardTextHeight} + ${m('--spacing-l')})`};
  padding: ${m('--spacing-m')};
`;

export const textOverlapCss = css`
  position: absolute;
  inset: auto 0 0 0;
`;

export const arrowCss = css`
  fill: ${m('--action-default-color')};
  height: ${arrowIconSize};
  width: ${arrowIconSize};
  grid-column: 2;

  [dir='rtl'] & {
    transform: rotate(180deg);
  }
`;

export const infoElementCss = css`
  grid-column: span 2;
  margin: 0;

  svg + & {
    grid-row: 1;
    grid-column: span 1;
  }
`;

export const titleCss = css`
  ${h5Css}
  ${lineClamp(2)}
  letter-spacing: 0.5px;
  margin-block-end: ${m('--spacing-xxs')};
`;

export const titleOnlyClampCss = css`
  ${lineClamp(4)}
`;

export const subtitleCss = css`
  ${h6Css}
  ${lineClamp(3)}
  letter-spacing: 0.25px;
  margin-block-end: ${m('--spacing-xs')};
`;

export const subtitleOnlyClampCss = css`
  ${lineClamp(5)}
`;

export const bodyCss = css`
  ${p3Css}
  ${lineClamp(4)}

  a {
    color: ${m('--carousel-card-fg-color')};
  }
`;

export const bodyOnlyClampCss = css`
  ${lineClamp(5)}
`;

export const imageCss = css`
  display: block;
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
`;

export const videoCss = css`
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
`;

/** Style to disable border radius when connecting video w/ player controls to text card */
export const videoPlayerControlsCss = css`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

/** Style to disable border radius when connecting text card to video w/ player controls */
export const textPlayerControlsCss = css`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

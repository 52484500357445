import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Document } from '@contentful/rich-text-types';
import { css } from '@emotion/css';
import type {
  DropdownField as DropdownFieldType,
  InputField as ContentfulInputType,
} from '@snapchat/mw-contentful-schema';
import type {
  InputType,
  MultiSelectOption,
  ValidationKey,
} from '@snapchat/snap-design-system-marketing';
import {
  Button,
  Input,
  MultiSelectDropdown,
  RadioSelectField,
  Select,
} from '@snapchat/snap-design-system-marketing';
import omit from 'lodash-es/omit';
import type { FC } from 'react';

import { mediaQuery } from '../../styles/mediaQueries';
import { getContentfulInspectorProps } from '../../utils/contentful/getContentfulInspectorProps';
import type { DropdownOption, FieldByTypeProps } from './types';
import { presetDropdowns } from './utils';

const buttonContainerCss = css`
  width: 100%;
  display: inline-flex;
  justify-content: center;

  ${mediaQuery.over768_desktop_small} {
    width: auto;
  }
`;

export const FieldByType: FC<FieldByTypeProps> = ({
  contentfulInputField: field,
  submitText,
  submitSuccessText,
  submitTextDataset,
  submitProps,
  initialValue: fieldInitialValue,
}) => {
  const typename = field.__typename;

  if (typename === 'InputField') {
    const inputInspectorProps = getContentfulInspectorProps<ContentfulInputType>({
      entryId: field.sys.id,
      fieldIds: ['richLabel', 'label', 'error', 'helpText', 'contentfulDescription'],
    });
    const error = field.error ? documentToReactComponents(field.error.json as Document) : null;
    const richLabel = field.richLabel
      ? documentToReactComponents(field.richLabel.json as Document)
      : null;

    // NOTE: do not touch this logic - it is needed for setting the Hidden fields on the Custom AR Download form.
    // https://github.sc-corp.net/Snapchat/marketing-web/pull/3561/files#r5823670
    const initialValue =
      (field.type !== 'Hidden' ? fieldInitialValue : undefined) ?? field.initialValue;

    return (
      <Input
        id={field.name!}
        key={field.sys.id}
        name={field.name!}
        // @ts-ignore: The types are correct. Typecheck is failing here.
        type={field.type as InputType | undefined}
        validation={field.validation as ValidationKey}
        label={field.label}
        required={field.required}
        placeholder={field.placeholder}
        // @ts-ignore: There's no 'readOnly' prop in the Input component, but we set it synthetically for SPS.
        readOnly={field.readOnly}
        maxLength={field.maxLength}
        shouldResetToInitial={field.shouldResetToInitial}
        helpText={field.helpText}
        minValue={field.minValue}
        maxValue={field.maxValue}
        dataset={inputInspectorProps.contentfulDescriptionDataset}
        {...inputInspectorProps}
        error={error}
        richLabel={richLabel}
        initialValue={initialValue}
      />
    );
  }

  const dropdownDatasets = getContentfulInspectorProps<DropdownFieldType>({
    entryId: field.sys.id,
    fieldIds: ['label', 'error', 'helpText', 'contentfulDescription'],
  });

  if (typename === 'DropdownField') {
    const optionCollectionItems = field.optionsCollection?.items ?? [];
    const contentfulInitialValue = field.initialOption?.value;
    const initialValue = fieldInitialValue ?? contentfulInitialValue;
    const allValues = optionCollectionItems.map(({ value }) => value ?? '');
    const initialSelectValue =
      typeof initialValue === 'string' && allValues.includes(initialValue)
        ? initialValue
        : contentfulInitialValue;
    const error = field.error ? documentToReactComponents(field.error.json as Document) : null;
    // TODO: Add richLabel to the dropdown field.

    return (
      <Select
        key={field.sys.id}
        id={field.name!}
        name={field.name!}
        label={field.label}
        initialValue={initialSelectValue}
        allValues={allValues}
        error={error}
        shouldResetToInitial={field.shouldResetToInitial}
        helpText={field.helpText}
        required={field.required}
        placeholder={field.placeholder}
        {...omit(dropdownDatasets, 'contentfulDescriptionDataset')}
        fieldDataset={dropdownDatasets.contentfulDescriptionDataset}
      >
        {optionCollectionItems.map(({ key, value }) => (
          <option key={key} value={value || key}>
            {key}
          </option>
        ))}
      </Select>
    );
  }

  if (typename === 'MultiselectDropdownField') {
    const optionCollectionItems = field.optionsCollection?.items ?? [];
    const error = field.error ? documentToReactComponents(field.error.json as Document) : null;
    // TODO: Add richLabel to the multiselect field.

    const initialValue =
      Array.isArray(fieldInitialValue) && fieldInitialValue.every(item => typeof item === 'object')
        ? (fieldInitialValue as MultiSelectOption[])
        : undefined;
    const options = optionCollectionItems
      .map(({ key, value }) => {
        if (!value || !key) return null;
        return { name: value, id: key };
      })
      .filter(Boolean);

    return (
      <MultiSelectDropdown
        name={field.name!}
        label={field.label}
        showCheckbox
        options={options as MultiSelectOption[]}
        required={field.required}
        placeholder={field.placeholder}
        error={error}
        {...omit(dropdownDatasets, 'contentfulDescriptionDataset')}
        dataset={dropdownDatasets.contentfulDescriptionDataset}
        initialValues={initialValue}
      />
    );
  }

  if (typename === 'PresetDropdownField') {
    const presetOptions = field.preset ? presetDropdowns[field.preset] : undefined;
    if (!presetOptions) return null;
    const allValues = presetOptions.data.map(({ value }) => value);
    const error = field.error ? documentToReactComponents(field.error.json as Document) : null;
    // TODO: Add richLabel to the select field.

    return (
      <Select
        key={field.sys.id}
        id={field.name!}
        error={error}
        placeholder={field.placeholder ?? presetOptions.defaultPlaceholder}
        {...omit(dropdownDatasets, 'contentfulDescriptionDataset')}
        name={field.name!}
        label={field.label}
        fieldDataset={dropdownDatasets.contentfulDescriptionDataset}
        allValues={allValues}
        initialValue={fieldInitialValue ?? field.initialValue}
        required={field.required}
      >
        {presetOptions.data.map(({ key, value }: DropdownOption) => (
          <option key={key} value={value}>
            {key}
          </option>
        ))}
      </Select>
    );
  }

  if (typename === 'RadioSelectField') {
    const optionCollectionItems =
      field.optionsCollection?.items?.map(({ key, value }) => ({ key: key!, value })) ?? [];
    const contentfulInitialValue = field.initialOption?.value;
    const allValues = optionCollectionItems.map(({ value }) => value ?? '');
    const initialSelectValue =
      typeof contentfulInitialValue === 'string' && allValues.includes(contentfulInitialValue)
        ? contentfulInitialValue
        : undefined;
    const error = field.error ? documentToReactComponents(field.error.json as Document) : null;

    return (
      <RadioSelectField
        key={field.sys.id}
        error={error}
        helpText={field.helpText}
        initialValue={initialSelectValue}
        label={field.label}
        name={field.name!}
        options={optionCollectionItems}
        required={field.required}
        shouldResetToInitial={field.shouldResetToInitial}
      />
    );
  }

  if (typename === 'SubmitButton' && submitProps) {
    const { handleSubmit, disabled, loading, submitSuccess } = submitProps;
    return (
      <div className={buttonContainerCss}>
        <Button
          type="Primary"
          size="Large"
          onClick={handleSubmit}
          disabled={disabled}
          loading={loading}
          buttonTextDataset={submitTextDataset}
        >
          {submitSuccess ? submitSuccessText ?? submitText : submitText}
        </Button>
      </div>
    );
  }

  return null;
};

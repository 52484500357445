import { css } from '@emotion/css';

import { mobileMediaQuery, nonMobileMediaQuery } from '../../constants';
import { m } from '../../motif';

export const carouselAnimationDuration = 300;

export const carouselContainerCss = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-block-end: 20px;
  width: 100%;

  ${mobileMediaQuery} {
    overflow-x: hidden;
  }
`;

export const carouselContentWrapperCss = css`
  max-height: 100%;
  overflow: hidden;
  width: 100%;

  &.dragging {
    a {
      pointer-events: none;
    }
  }
`;

// offset the carousel container for better alignment
export const carouselContentWrapperOffsetCss = css`
  margin-inline-start: calc((${m('--carousel-card-mobile-grid-gap')} / 2) * -1);
  width: calc(100% + (${m('--carousel-card-mobile-grid-gap')} / 2));

  ${nonMobileMediaQuery} {
    margin-inline-start: calc((${m('--carousel-card-desktop-grid-gap')} / 2) * -1);
    width: calc(100% + (${m('--carousel-card-desktop-grid-gap')} / 2));
  }
`;

export const carouselCenteredCss = css`
  justify-content: center;
`;

export const carouselGapCss = css`
  gap: ${m('--carousel-card-mobile-grid-gap')};

  ${nonMobileMediaQuery} {
    gap: ${m('--carousel-card-desktop-grid-gap')};
  }
`;

export const carouselSingleViewDefaultTransformCss = css`
  transform: translateX(-100%);
`;

export const carouselContentCss = css`
  display: flex;
  margin-block: ${m('--spacing-xl')};
  transition: all ${carouselAnimationDuration}ms linear;

  html[dir='rtl'] &,
  body[dir='rtl'] & {
    direction: rtl;
  }
`;

export const carouselSlideCss = css`
  opacity: 1;

  &.fadeOut {
    opacity: 0;
    pointer-events: none;
  }

  &.animate {
    transition: opacity ${carouselAnimationDuration / 2}ms ease;
  }
`;

export const carouselFillSlidesCss = css`
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  width: 100%;
`;

export const dotWrapperCss = css`
  align-items: center;
  background-color: transparent;
  border: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  height: 20px;
  width: 20px;
`;

export const dotCss = css`
  background-color: ${m('--carousel-inactive-dot-color')};
  transition: background-color 200ms;
  border-radius: 100px;
  height: 8px;
  width: 8px;

  ${nonMobileMediaQuery} {
    cursor: pointer;
  }
`;

export const dotActiveCss = css`
  background-color: ${m('--carousel-active-dot-color')};
`;

export const dotsInnerContainerCss = css`
  -ms-overflow-style: none;
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 20px;
  margin-block-end: ${m('--spacing-xl')};
  padding-inline-start: 0;
  list-style: none;
  margin: 0 auto;
  scrollbar-width: none;

  html[dir='rtl'] & {
    direction: rtl;
  }

  > div:first-child {
    margin-inline-start: 0;
  }
`;

export const withDotsCss = css`
  padding-block-end: 0;
`;

export const dotsContainerCss = css`
  display: flex;
  justify-content: center;
`;

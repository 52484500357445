import type {
  Accordion as AccordionType,
  Content as ContentType,
} from '@snapchat/mw-contentful-schema';
import {
  Accordion as AccordionSDS,
  AccordionItem as AccordionItemSDS,
} from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import type { ContentfulSysProps } from 'src/types/contentful';

import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { isContentfulSysProps } from '../../utils/contentful';
import { getContentfulInspectorProps } from '../../utils/contentful/getContentfulInspectorProps';
import { renderRichTextMarkingsOnly } from '../../utils/renderText/renderRichText';
import { Content } from '../Content';
import { Feature, useFeatureFlags } from '../FeatureFlags';
import { queries } from './query';
import type { AccordionDataHandlerProps } from './types';

export const Accordion: FC<ContentfulSysProps> = (props: ContentfulSysProps) => {
  const featureFlags = useFeatureFlags();
  const shouldUseContentAccordionItems =
    featureFlags[Feature.USE_CONTENT_ACCORDION_ITEMS] === 'true';
  const id = isContentfulSysProps(props) ? props.sys.id : undefined;

  const { data } = useContentfulQuery<AccordionDataHandlerProps, ContentfulIdVariable>(
    queries.all,
    {
      skip: !id,
      variables: { id },
    }
  );

  if (!data) return null;

  const { titleDataset } = getContentfulInspectorProps<AccordionType>({
    entryId: id!,
    fieldIds: ['title'],
  });

  let accordionItems = data?.accordion.itemsCollection.items;

  if (shouldUseContentAccordionItems) {
    accordionItems = accordionItems.filter(item => item.__typename === 'Content');
  } else {
    accordionItems = accordionItems.filter(item => item.__typename === 'AccordionItem');
  }

  return (
    <AccordionSDS
      data-testid="accordion"
      multipleOpen={data?.accordion.multipleOpen}
      title={data?.accordion.title}
      titleDataset={titleDataset}
    >
      {accordionItems.map(item => {
        const { titleDataset } = getContentfulInspectorProps<ContentType>({
          entryId: item.sys.id,
          fieldIds: ['title'],
        });

        return (
          <AccordionItemSDS
            id={item.sys.id}
            key={item.sys.id}
            title={renderRichTextMarkingsOnly(item.title)}
            titleDataset={titleDataset}
          >
            <Content {...item} title={undefined} />
          </AccordionItemSDS>
        );
      })}
    </AccordionSDS>
  );
};

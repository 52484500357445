import { gql } from '@apollo/client';

export const assetFragment = gql`
  fragment AssetAll on Asset {
    sys {
      id
    }
    contentType
    width
    height
    title
    description
    url
    size
  }
`;

// TODO: Delete references to this.
export const fragments = {
  all: assetFragment,
};

export const query = gql`
  query AssetsQuery($preview: Boolean!, $locale: String!, $id: String!) {
    asset(preview: $preview, locale: $locale, id: $id) {
      ...AssetAll
    }
  }
  ${assetFragment}
`;

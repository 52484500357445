import type { ReactNode } from 'react';

import type { BackgroundColor } from '../../constants';
import type { BaseComponentProps } from '../../types';
import type { NavigatorItemProps } from '../Navigator/Item/types';

export type GlobalHeaderNavItemAlignment = 'left' | 'right' | 'center';
export interface GlobalHeaderProps extends BaseComponentProps {
  logo: ReactNode;
  siteName?: ReactNode;
  trackingSiteName?: string;
  defaultGroupKey?: string;
  backgroundColor: BackgroundColor;
  cta?: ReactNode;
  localNavDesktop?: ReactNode;
  children?: ReactNode;
  displayed?: boolean;
  onToggleExpanded?: (isExpanded: boolean) => void;
  showNavScreen?: boolean;
  endChildrenClassName?: string;
  /** Whether to display the waffle icon on desktop and have global links in mobile at all. */
  showGlobalLinks?: boolean;

  /**
   * Variable that must remain the same for the nav to stay open.
   *
   * If this "invariant" changes, the nav closes. Canonical example of this is the current URL path.
   */
  stayOpenInvariant?: string;

  /**
   * Checks whether a given URL is the same as the current window URL. Used in determining
   * navigation breadcrumbs.
   */
  isUrlCurrent?: (url: string) => boolean;

  /** @deprecated - The navigation tree for the header. Used in determining navigation breadcrumbs. */
  headerNavigationTree?: NavigationItem[];

  /** Feauter flag tested alignment of nav items on desktop */
  navItemAlignment?: GlobalHeaderNavItemAlignment;

  /** Aria label for the menu toggle button */
  buttonAriaLabel?: string;
}

interface InternalGlobalHeaderProps {
  toggleExpanded: () => void;
  isExpanded: boolean;
  showNavScreen?: boolean;
}

/** Inherits from NavigatorItemProps so we keep the types in sync. */
type BaseNavigatorItem = Pick<NavigatorItemProps, 'id' | 'url'>;
export interface NavigationItem extends BaseNavigatorItem {
  subItems?: NavigationItem[];
}

export type GlobalHeaderMobileProps = Omit<
  GlobalHeaderProps,
  'backgroundColor' | 'localNavDesktop' | 'children'
> &
  InternalGlobalHeaderProps;
export type GlobalHeaderDesktopProps = Omit<GlobalHeaderProps, 'backgroundColor' | 'children'> &
  InternalGlobalHeaderProps & { navItemAlignment?: GlobalHeaderNavItemAlignment };

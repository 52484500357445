import { createContext } from 'react';

import type { MediaMode } from '../../constants';
import type { ToggleState } from '../../hooks/useToggleState';

interface GlobalHeaderContextProps {
  /** Narrow (Mobile) or Wide (Desktop) display mode. */
  mode?: MediaMode;

  /** Selected group key. Controls the highlight, highlight CTA and active indicator. */
  groupKey?: string;
  setGroupKey?: (groupKey: string) => void;

  /** Handler for the open/close buttons to display the nav. */
  toggleExpanded?: () => void;

  /** State of the expansion; useful for animation tracking. */
  screenState?: ToggleState;

  /** Name of the site as visible as UTM source parameter for outgoing link tracking. */
  trackingSiteName?: string;

  /** @deprecated - A set of urls from the navigation tree that should be highlighted */
  navigationBreadcrumbs?: Set<string>;

  /**
   * Function for determining whether a url is the current location. Used for setting selected state
   * of navigation items.
   */
  isUrlCurrent?: (url: string) => boolean;
}

export const GlobalHeaderContext = createContext<GlobalHeaderContextProps>({});

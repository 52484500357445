import { htmlEscape, htmlUnescape } from '@snapchat/core';
import {
  AutoComplete as AutoCompleteSDS,
  FormattedMessage,
  Header,
  MessageContext,
  MotifComponent,
  Pagination,
  Spinner,
  useMotifStyles,
} from '@snapchat/snap-design-system-marketing';
import noop from 'lodash-es/noop';
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { AppContext } from '../../AppContext';
import { ConsumerContext } from '../../components/ConsumerContextProvider';
import { totalHeaderHeightCssVar } from '../../components/Header/headerSizeUtils';
import type { SearchResult } from '../../components/Search';
import { NoResultsFound, SearchResultItem } from '../../components/Search';
import { ActiveEventCountTracker } from '../../components/tracing/ActiveEventCountTracker';
import { logEvent, logUserEvent, SubscribedEventType } from '../../helpers/logging';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useSearchResults } from '../../hooks/useSearchResults';
import { UserAction } from '../../types/events';
import {
  searchAutocompleteCss,
  searchContainerCss,
  searchResultsContainerCss,
  searchResultsListCss,
  searchSubtitleCss,
  spinnerContainerCss,
} from './styles';

const maxResultsPerPage = 20;
// 250 seems appropriate as it balances search responsiveness and debouncing times per second.
const searchDebounceDelay = 250;

// 1000ms debounce delay for logging the page view event
const pageViewDebounceDelay = 1000;

/**
 * Replaces invisible commas with ellipses and removes ellipses at the start or end of the string
 * for each element in the array. Concatenates the modified elements into a single string separated
 * by ellipses.
 *
 * @param elements - An array of strings to be processed.
 * @returns A string with modified elements joined by ellipses.
 */
const getDescription = (elements: Array<string>) => {
  const result = elements
    .map(element => element.replace(/ ?(\u2063+)/gm, '\u2026').replace(/^\u2026+|\u2026+$/g, ''))
    .join('\u2026 ');

  return result;
};

const handleScroll = (element: HTMLDivElement | null) => {
  // Using requestAnimationFrame to avoid forced reflow  and solve inconsistencies with smooth scroll.
  window.requestAnimationFrame(() => {
    const totalHeaderHeight = Number.parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(totalHeaderHeightCssVar),
      10
    );

    const containerTop = element?.getBoundingClientRect().top ?? 0;
    const scrollY = window.scrollY;

    // To account for the height of the sticky header, add totalHeaderHeight and additionalSpaceAboveHeader
    // to create white space between the top of the element and the header.
    window.scrollTo({
      top: containerTop + scrollY - (totalHeaderHeight + 20),
      behavior: 'smooth',
    });
  });
};

export const Search: FC = () => {
  const query = useQueryParams();
  const searchQueryParameter = query.get('q') ?? '';
  const searchPageParameter = Number(query.get('page') ?? '1');
  const [shouldRenderSpinner, setShouldRenderSpinner] = useState(true);

  // Initialize Motif styles for the Search component
  useMotifStyles(MotifComponent.SEARCH);

  const { currentLocale } = useContext(AppContext);
  const { formatMessage } = useContext(MessageContext);
  const { getUrlParams, setUrlParams } = useContext(ConsumerContext);

  const containerRef = useRef<HTMLDivElement>(null);

  const [page, setPage] = useState(searchPageParameter);
  const [searchTerm, setSearchTerm] = useState(searchQueryParameter);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  // effect to set debounced searchTerm
  useEffect(() => {
    if (searchTerm === debouncedSearchTerm) {
      return;
    }

    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setPage(1);
      setShouldRenderSpinner(true);
    }, searchDebounceDelay);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm, debouncedSearchTerm]);

  //  effect to log page view event every time we think a new search is being performed
  useEffect(() => {
    const timerId = setTimeout(() => {
      logEvent({
        subscribedEventType: SubscribedEventType.PAGE_LOAD,
      });
    }, pageViewDebounceDelay);

    return () => {
      clearTimeout(timerId);
    };
  });

  const searchResults = useSearchResults(
    debouncedSearchTerm,
    currentLocale,
    page,
    maxResultsPerPage
  );

  useEffect(() => {
    if (searchResults.isLoading) {
      return;
    }

    setShouldRenderSpinner(false);
  }, [searchResults.isLoading]);

  // effect to change url when state changes
  useEffect(() => {
    if (!getUrlParams || !setUrlParams) {
      return;
    }

    const queryParams = new URLSearchParams(getUrlParams());

    // Purposely not using `searchPageParameter` and `searchQueryParameter` to
    // avoid render loop between this and the `Handle back button click` useEffect
    if (
      debouncedSearchTerm === (queryParams.get('q') ?? '') &&
      page.toString() === queryParams.get('page')
    ) {
      return;
    }

    queryParams.set('q', debouncedSearchTerm);
    queryParams.set('page', `${page}`);

    setUrlParams({ ...Object.fromEntries(queryParams) });
  }, [currentLocale, debouncedSearchTerm, page, getUrlParams, setUrlParams]);

  // Handle back button click
  useEffect(() => {
    const newPage = searchPageParameter;
    const newTerm = searchQueryParameter;

    setPage(newPage);
    setSearchTerm(newTerm);
    setDebouncedSearchTerm(newTerm);
  }, [searchPageParameter, searchQueryParameter]);

  const onChange = (term: string) => {
    const trimmedTerm = term.trimStart();
    setSearchTerm(trimmedTerm);
  };

  const onChangePage = useCallback(
    (newPage: number) => {
      setPage(newPage);
      handleScroll(containerRef.current);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [containerRef.current]
  );

  const showLoading = shouldRenderSpinner && searchResults.isLoading && !searchResults.isErrored;
  const showResults = !showLoading && !!searchResults.data.total;
  const showNoResults = !showLoading && !searchResults.data.total;

  return (
    <>
      <Header noIndex={true} />
      <div data-test-id="mwp-search" className={searchContainerCss} ref={containerRef}>
        <div className={searchAutocompleteCss}>
          <AutoCompleteSDS<SearchResult>
            autocompleteResults={[]}
            collapsible={false}
            hideSuggestions
            initialText={htmlUnescape(htmlEscape(searchTerm))}
            loadingAutocompleteTerms={false}
            onChange={onChange}
            onSelect={noop}
            placeholder={formatMessage({
              id: 'placeholderSearchPageInput',
              defaultMessage: 'Search...',
            })}
          />
          {searchResults && (
            <p className={searchSubtitleCss}>
              <FormattedMessage
                id="searchResultsSubtitle"
                defaultMessage={'{total} search results for "{term}"'}
                values={{
                  total: String(searchResults.data.total),
                  term: htmlEscape(debouncedSearchTerm),
                }}
              />
            </p>
          )}
        </div>
        <div className={searchResultsContainerCss}>
          {showLoading && (
            <div className={spinnerContainerCss}>
              <Spinner />
            </div>
          )}
          {showResults && (
            <ul className={searchResultsListCss}>
              {searchResults.data.results.map((item, i) => (
                <li key={item.pageTitle + searchTerm + i * page}>
                  <SearchResultItem
                    description={getDescription(item.highlights)}
                    pageTitle={item.pageTitle}
                    slug={item.slug}
                    onClick={() =>
                      logUserEvent({
                        eventCategory: 'SearchResult',
                        eventAction: UserAction.Click,
                        eventLabel: item.slug,
                      })
                    }
                  />
                </li>
              ))}
            </ul>
          )}
          {showNoResults && <NoResultsFound />}
        </div>

        {showResults ? (
          <Pagination
            currentPage={page}
            onChange={onChangePage}
            totalPages={Math.floor((searchResults.data.total - 1) / maxResultsPerPage) + 1}
          />
        ) : null}

        {/* Render the same test divs as the MWP Page component for use in release tests.
          NOTE: Purposely deferring rendering these until the search results are returned
                so our release tests check the results page rather than loading state. */}
        {!searchResults.isLoading && <ActiveEventCountTracker />}
      </div>
    </>
  );
};
